<template>
  <div
    :class="[
      'easyscreen-scrollable',
      { 'easyscreen-scrollable_visible-scroll-bar': $accessibility.enableLegendScrollBar }
    ]"
  >
    <button
      v-if="$accessibility.enableLegendScrollBar && scrollAvaiable && !scrollAtHome"
      :class="[
        'easyscreen-scrollable--scroll-button',
        'easyscreen-scrollable--scroll-button_scroll-top'
      ]"
      :title="$l10n('Scroll up')"
      @mousedown="startDecrementScroll"
      tabindex="0"
      @keydown.enter="startDecrementScroll"
    >
      <i class="far fa-chevron-up"></i>
    </button>
    <div class="easyscreen-scrollable--content-wrapper easyscreen-scrollable--smooth-edge">
      <div
        v-if="smoothEdgeColor"
        class="easyscreen-scrollable--smooth-edge-top"
        :style="{ backgroundImage: `linear-gradient(to bottom, ${ smoothEdgeColor } 15%, rgba(0,0,0,0) 100%)` }"
      ></div>
      <div
        :class="[
          'easyscreen-scrollable--content',
          {
            'easyscreen-scrollable--content_with-scroll-bar': visibleScrollBar === true,
            'easyscreen-scrollable--content_events-locked': contentEventsLocked
          }
        ]"
        :scroll-top.prop.camel="scrollTop"
        :style="{ '--content-height': maxHeight, pointerEvents: scrolling ? 'none' : '' }"
        ref="container"
        @mousedown="_dragStart"
        @touchstart="_dragStart"
        @scroll="_onContentScroll"
      >
        <div ref="content">
          <slot></slot>
        </div>
      </div>
      <div
        v-if="smoothEdgeColor"
        :data-smooth-edge-color="smoothEdgeColor"
        class="easyscreen-scrollable--smooth-edge-bottom"
        :style="{ backgroundImage: `linear-gradient(to top, ${ smoothEdgeColor } 15%, rgba(0,0,0,0) 100%)` }"
      ></div>
    </div>
    <button
      v-if="$accessibility.enableLegendScrollBar && scrollAvaiable && !scrollAtEnd"
      :class="[
        'easyscreen-scrollable--scroll-button',
        'easyscreen-scrollable--scroll-button_scroll-bottom'
      ]"
      :title="$l10n('Scroll down')"
      @mousedown="startIncrementScroll"
      tabindex="0"
      @keydown.enter="startIncrementScroll"
    >
      <i class="far fa-chevron-down"></i>
    </button>
  </div>
</template>

<style lang="less" src="./scrollable.less"></style>
<script>
  import lodash from "lodash";
  import htmlElementHeight from "../../lib/utils/html-element-height.js";
  import Animate from "../../lib/utils/animate.js";
  import orientationMixin from "../mixins/orientation.js";
  import dragAndDropMixin from "../mixins/drag-and-drop.js";

  export default {
    name: "easyscreen-scrollable",
    mixins: [orientationMixin, dragAndDropMixin],
    props: {
      /* The maximim height of scroll area. */
      maxHeight: [Number, String],
      /* Color of top and bottom gradients which do the content hiding is smoothly. */
      smoothEdgeColor: String,
      visibleScrollBar: Boolean,
      preventClicksAfterScroll: {
        type: Boolean,
        default: true
      },
      buttonScrollStep: {
        type: Number,
        default: 100
      },
      eventlyScrollStep: {
        type: Number,
        default: 8
      }
    },
    watch: {
      scrollAvaiable(newValue){
        this.$emit("scroll-avaiable", newValue);
      }
    },
    data() {
      return {
        scrollTop: 0,
        scrolling: false,
        contentEventsLocked: false,
        scrollAvaiable: false,
        scrollAtHome: false,
        scrollAtEnd: false
      };
    },
    created() {
      this.dragDirection = "vertical";
      this._stopEventlyScroll = this._stopEventlyScroll.bind(this);
      this._onContentResize = this._onContentResize.bind(this);
      this._onContentScroll = lodash.throttle(this._onContentScroll.bind(this), 16);
    },
    methods: {
      /**
       * Get the available scroll of the content area.
       *
       * @returns {Number} The available scroll in px.
       */
      getAvailableScroll() {
        if (!this.$refs.container)
          return 0;

        return this.$refs.container.scrollHeight - htmlElementHeight(this.$refs.container);
      },
      /**
       * Get the current scroll top of the content area.
       *
       * @returns {Number} The current scroll in px.
       */
      getScroll() {
        if (!this.$refs.container)
          return 0;

        return this.$refs.container.scrollTop;
      },
      /**
       * Set scroll position of the content area.
       * @async
       *
       * @param {Number} position - The position of scroll in px from top of the top of container.
       * @param {Object} [transition] - Transition settings.
       * @param {String} [transition.behavior="instant"] - The scroll transition - "instant" or "smooth".
       * @param {Number} [transition.duration=500] - The duration of transition.
       * @param {Number} [transition.easing="easeOutQuad"] - The easing of transition.
       */
      async setScroll(position, transition) {
        this.stopScrollTransition();
        transition = Object.assign({
          behavior: "instant",
          duration: 500,
          easing: "easeOutQuad"
        }, transition || {});

        position = lodash.clamp(position, 0, this.getAvailableScroll());

        if (transition.behavior !== "smooth") {
          this.scrollTop = position;
          return;
        }

        this._scrollTransition = new Animate({
          easingName: transition.easing,
          from: { scrollTop: this.getScroll() },
          to: { scrollTop: position },
          duration: transition.duration,
          tic: ({ scrollTop }) => {
            this.scrollTop = scrollTop;
          },
          done: () => {
            this._scrollTransition = null;
          }
        });

        return this._scrollTransition.start();
      },
      /**
       * Stop the transition of scroll.
       */
      stopScrollTransition() {
        if (this._scrollTransition) {
          this._scrollTransition.stop();
          this._scrollTransition = null;
        }
      },
      /**
       * Get the status of scroll transition.
       *
       * @return {Booelan} `true` - the scroll transition in progress.
       */
      scrollTransitionInProgress() {
        return Boolean(this._scrollTransition);
      },
      /**
       * Scroll to html element inside of container.
       *
       * @param {HTMLElement} htmlElement - The element to which should be visible after the container scroll.
       * @param {Object} [options] - The scroll options.
       * @param {String} [options.behavior="smooth"] - The scroll transition - "instant" or "smooth".
       * @param {String} [options.duration=500] - The duration in ms of smooth animation.
       * @param {String} [options.aligment="nearest"] - The aligment of element:
       * @param {Boolean} [options.scrollIfNeeded=true] - Do the scroll only when the element or part of element out of viewport.
       *   - "start" - The top of element will be placed at top of container.
       *   - "center" - The center of element will be placed at center of container.
       *   - "end" - The bottom of element will bt placed at bottom of container.
       *   - "nearest" - The "start", "center" or "end" will be automatically selected based on distance.
       */
      async scrollIntoView(htmlElement, options) {
        options = Object.assign({
          behavior: "smooth",
          duration: 500,
          aligment: "nearest",
          scrollIfNeeded: true
        }, options || {});

        const containerBoundings = this.$refs.container.getBoundingClientRect();
        const elementBoundings = htmlElement.getBoundingClientRect();
        const isOutOfViewport =
          containerBoundings.top > elementBoundings.top ||
          containerBoundings.top > elementBoundings.bottom ||
          containerBoundings.bottom < elementBoundings.top ||
          containerBoundings.bottom < elementBoundings.bottom;

        if (options.scrollIfNeeded === true && isOutOfViewport !== true) {
          return;
        }

        const scaleY = htmlElement.offsetHeight / elementBoundings.height;
        const toStart = elementBoundings.top - containerBoundings.top;
        const toCenter = (elementBoundings.top + elementBoundings.bottom) / 2 - (containerBoundings.top + containerBoundings.bottom) / 2;
        const toEnd = elementBoundings.bottom - containerBoundings.bottom;

        let scrollOffset = toStart; // aligment: "start"
        if (options.aligment === "nearest") {
          [toStart, toCenter, toEnd].forEach(_offset => {
            if (Math.abs(scrollOffset) > Math.abs(_offset)) {
              scrollOffset = _offset;
            }
          });
        } else if (options.aligment === "center") {
          scrollOffset = toCenter;
        } else if (options.aligment === "end") {
          scrollOffset = toEnd;
        }

        this.setScroll(this.getScroll() + scrollOffset * scaleY, {
          behavior: options.behavior,
          duration: options.duration
        });
      },
      decrementScroll(step, options) {
        this.setScroll(this.getScroll() - (step || this.buttonScrollStep), {
          behavior: "smooth",
          duration: 100,
          ...(options || {})
        });
      },
      incrementScroll(step, options) {
        this.setScroll(this.getScroll() + (step || this.buttonScrollStep), {
          behavior: "smooth",
          duration: 100,
          ...(options || {})
        });
      },
      startDecrementScroll() {
        this._startEventlyScroll("decrementScroll");
      },
      startIncrementScroll() {
        this._startEventlyScroll("incrementScroll");
      },
      _startEventlyScroll(method) {
        this._stopEventlyScroll();

        this[method]();

        this._eventlyScrollIntervalTimeout = setTimeout(() => {
          this._eventlyScrollInterval = setInterval(() => {
            this[method](this.eventlyScrollStep, { behavior: "instant" });
          }, 16);
        }, 116);

        window.addEventListener("mouseup", this._stopEventlyScroll);
      },
      _stopEventlyScroll() {
        clearTimeout(this._eventlyScrollIntervalTimeout);
        clearInterval(this._eventlyScrollInterval);
        this._eventlyScrollIntervalTimeout = null;
        this._eventlyScrollInterval = null;

        window.removeEventListener("mouseup", this._stopEventlyScroll);
      },
      _moveStart() {
        this.lastScrollTop = this.getScroll();
        this.availableScroll = this.getAvailableScroll();
        this._lockContentEvents();
      },
      _moveTick(event) {
        this.scrollTop = lodash.clamp(this.lastScrollTop - this._applyScreenScale(event.deltaY), 0, this.availableScroll);
      },
      _moveEnd() {
        this._unlockContentEvents();
      },
      _lockContentEvents() {
        this.contentEventsLocked = true;
        clearTimeout(this._unlockContentEventsTimeout);
        this._unlockContentEventsTimeout = null;
      },
      _unlockContentEvents() {
        this._unlockContentEventsTimeout = setTimeout(() => {
          this.contentEventsLocked = false;
        }, 100);
      },
      _onContentScroll() {
        const availableScroll = this.getAvailableScroll();
        const scrollPosition = this.getScroll();

        this.scrollAtHome = scrollPosition === 0;
        this.scrollAtEnd = Math.ceil(scrollPosition) === Math.ceil(availableScroll);
      },
      _onContentResize() {
        this.scrollAvaiable = this.getAvailableScroll() !== 0;
        this._onContentScroll();
      },
      _initResizeObserver() {
        this._destroyResizeObserver();

        this.resizeObserver = new ResizeObserver(this._onContentResize);
        this.resizeObserver.observe(this.$refs.content);
      },
      _destroyResizeObserver() {
        if (this.resizeObserver) {
          this.resizeObserver.disconnect();
          this.resizeObserver = null;
        }
      }
    },
    mounted() {
      if (this.$store.state.isMobile !== true) {
        this.$on("dnd-start", this._moveStart);
        this.$on("dnd-move", this._moveTick);
        this.$on("dnd-end", this._moveEnd);
      }

      if (this.$accessibility.enableLegendScrollBar) {
        this._initResizeObserver();
      }
    },
    beforeDestroy() {
      this.$off("dnd-start", this._moveStart);
      this.$off("dnd-move", this._moveTick);
      this.$off("dnd-end", this._moveEnd);
      this.stopScrollTransition();
      this._stopEventlyScroll();
      this._destroyResizeObserver();
    },
    components: {}
  };
</script>
