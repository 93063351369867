var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-modal',{class:("vm_" + _vm.position),attrs:{"name":_vm.modalId,"shiftX":0,"shiftY":0,"width":0,"height":'auto',"click-to-close":_vm.clickToClose},on:{"before-open":function (event) {
    _vm.isShown = true;
    _vm.$emit('before-open', event);
  },"opened":function (event) { _vm.$emit('opened', event); _vm._changeFocusLayer() },"before-close":function (event) {
    _vm.isShown = false;
    _vm.$emit('before-close', event);
  },"closed":function (event) { _vm.$emit('closed', event); _vm.resetFocus() }}},[_c('div',{staticClass:"v-modal--content",style:({
    minWidth: _vm.minWidth,
    minHeight: _vm.minHeight,
    maxWidth: _vm.maxWidth,
    maxHeight: _vm.maxHeight
  })},[_c('div',{ref:"modalContent",attrs:{"tabindex":"-1"}},[_vm._t("default")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }