var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'easyscreen-scrollable',
    { 'easyscreen-scrollable_visible-scroll-bar': _vm.$accessibility.enableLegendScrollBar }
  ]},[(_vm.$accessibility.enableLegendScrollBar && _vm.scrollAvaiable && !_vm.scrollAtHome)?_c('button',{class:[
      'easyscreen-scrollable--scroll-button',
      'easyscreen-scrollable--scroll-button_scroll-top'
    ],attrs:{"title":_vm.$l10n('Scroll up'),"tabindex":"0"},on:{"mousedown":_vm.startDecrementScroll,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.startDecrementScroll($event)}}},[_c('i',{staticClass:"far fa-chevron-up"})]):_vm._e(),_c('div',{staticClass:"easyscreen-scrollable--content-wrapper easyscreen-scrollable--smooth-edge"},[(_vm.smoothEdgeColor)?_c('div',{staticClass:"easyscreen-scrollable--smooth-edge-top",style:({ backgroundImage: ("linear-gradient(to bottom, " + _vm.smoothEdgeColor + " 15%, rgba(0,0,0,0) 100%)") })}):_vm._e(),_c('div',{ref:"container",class:[
        'easyscreen-scrollable--content',
        {
          'easyscreen-scrollable--content_with-scroll-bar': _vm.visibleScrollBar === true,
          'easyscreen-scrollable--content_events-locked': _vm.contentEventsLocked
        }
      ],style:({ '--content-height': _vm.maxHeight, pointerEvents: _vm.scrolling ? 'none' : '' }),domProps:{"scrollTop":_vm.scrollTop},on:{"mousedown":_vm._dragStart,"touchstart":_vm._dragStart,"scroll":_vm._onContentScroll}},[_c('div',{ref:"content"},[_vm._t("default")],2)]),(_vm.smoothEdgeColor)?_c('div',{staticClass:"easyscreen-scrollable--smooth-edge-bottom",style:({ backgroundImage: ("linear-gradient(to top, " + _vm.smoothEdgeColor + " 15%, rgba(0,0,0,0) 100%)") }),attrs:{"data-smooth-edge-color":_vm.smoothEdgeColor}}):_vm._e()]),(_vm.$accessibility.enableLegendScrollBar && _vm.scrollAvaiable && !_vm.scrollAtEnd)?_c('button',{class:[
      'easyscreen-scrollable--scroll-button',
      'easyscreen-scrollable--scroll-button_scroll-bottom'
    ],attrs:{"title":_vm.$l10n('Scroll down'),"tabindex":"0"},on:{"mousedown":_vm.startIncrementScroll,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.startIncrementScroll($event)}}},[_c('i',{staticClass:"far fa-chevron-down"})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }