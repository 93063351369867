<template>
  <v-modal
    :name="modalId"
    :shiftX="0"
    :shiftY="0"
    :width="0"
    :height="'auto'"
    :click-to-close="clickToClose"
    @before-open="(event) => {
      isShown = true;
      $emit('before-open', event);
    }"
    @opened="(event) => { $emit('opened', event); _changeFocusLayer() }"
    @before-close="(event) => {
      isShown = false;
      $emit('before-close', event);
    }"
    @closed="(event) => { $emit('closed', event); resetFocus() }"
    :class="`vm_${ position }`"
  >
    <div class="v-modal--content" :style="{
      minWidth: minWidth,
      minHeight: minHeight,
      maxWidth: maxWidth,
      maxHeight: maxHeight
    }">
      <div ref="modalContent" tabindex="-1">
        <slot></slot>
      </div>
    </div>
  </v-modal>
</template>

<style lang="less" src="./blank.less" />
<script>
  import Vue from "vue";
  /*
   * Modals system.
   * https://www.npmjs.com/package/vue-js-modal
   */
  import VModal from "vue-js-modal";

  import { isFunction } from "lodash";
  import randomId from "../../../lib/random-id.cjs";

  Vue.use(VModal, { componentName: "v-modal" });

  export default {
    name: "modal-blank",
    props: {
      /* The min width of modal. */
      minWidth: {
        type: [Number, String],
        default: "705px"
      },
      /* The min height of modal. */
      minHeight: {
        type: [Number, String],
        default: "250px"
      },
      /* The max width of modal. */
      maxWidth: [Number, String],
      /* The max height of modal. */
      maxHeight: [Number, String],
      /* Flag for close modal by clicking on backdrop. */
      clickToClose: {
        type: Boolean,
        default: true
      },
      /* The type of modal position: fixed or absolute (switched the css position for root container). */
      position: {
        type: String,
        default: "fixed",
        validator: _position => ["fixed", "absolute"].includes(_position)
      },
      /* Close the popup when standby is started. */
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        modalId: randomId(),
        isShown: false
      };
    },
    methods: {
      /**
       * Show the current modal. Returns promise when callback is not provided.
       * @async
       *
       * @param {Function} [callback] - The show callback, will be called when modal is opened.
       */
      show(callback) {
        if (!callback) {
          return new Promise(resolve => this.show(resolve));
        }

        if (this.isShown) {
          if (isFunction(callback)) {
            callback();
          }

          return;
        }

        if (isFunction(callback)) {
          this.$once("opened", callback);
        }

        this.$modal.show(this.modalId);
      },
      /**
       * Hide the current modal. Returns promise when callback is not provided.
       * @async
       *
       * @param {Function} [callback] - The hide callback, will be called when modal is closed.
       */
      hide(callback) {
        if (!callback) {
          return new Promise(resolve => this.hide(resolve));
        }

        if (!this.isShown) {
          if (isFunction(callback)) {
            callback();
          }

          return;
        }

        if (isFunction(callback)) {
          this.$once("closed", callback);
        }

        this.$modal.hide(this.modalId);
      },
      resetFocus(){
        window.dispatchEvent(new CustomEvent("wayfinder-blur", { target: document.activeElement }));
      },
      _changeFocusLayer() {
        this.resetFocus();
        this.$refs.modalContent.focus();
      },
      catchFocus(e) {
        if (!this.isShown)
          return;

        let avalaibleToFocusElements = this.$refs.modalContent.children[0]
          .querySelectorAll("a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled])," +
            'input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]),' +
            " select:not([disabled])");

        let firstFocusableEl = avalaibleToFocusElements[0];
        let lastFocusableEl = avalaibleToFocusElements[avalaibleToFocusElements.length - 1];

        let isTabPressed = (e.key === "Tab" || e.keyCode === 13);

        if (!isTabPressed) {
          return;
        }

        if (e.shiftKey) {
          if (document.activeElement === firstFocusableEl) {
            lastFocusableEl.focus();
            e.preventDefault();
          }
        } else {
          if (document.activeElement === lastFocusableEl) {
            firstFocusableEl.focus();
            e.preventDefault();
          }
        }
      }
    },
    mounted() {
      window.addEventListener("keydown", this.catchFocus);
    },
    beforeDestroy() {
      window.removeEventListener("keydown", this.catchFocus);
    },
    screenPending() {
      if (this.autoClose) {
        this.hide();
      }
    }
  };
</script>
