<template>
  <div
    :style="{ height: contentHeight }"
    :class="[
      'wayfinder-collapsable',
      { 'wayfinder-collapsable_collapsed': localCollapsed }
    ]"
  >
    <div ref="contentWrapper" class="wayfinder-collapsable--content-wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<style src="./collapsable.less" lang="less"></style>
<script>
  import asyncTimeout from "../../../dashboard/lib/async-timeout.js";
  import htmlElementHeight from "../../lib/utils/html-element-height.js";

  export default {
    name: "wayfinder-collapsable",
    props: {
      collapsed: {
        type: Boolean,
        default: false
      },
      htmlContent: String
    },
    watch: {
      collapsed(newValue) {
        this._applyTransition(newValue);
      }
    },
    data() {
      return {
        localCollapsed: this.collapsed,
        contentHeight: null
      };
    },
    methods: {
      async _applyTransition(collapsed) {
        if (this.transitionTimeout) {
          clearTimeout(this.transitionTimeout._timeout);
        }

        if (this.htmlContent)
          this.$refs.contentWrapper.innerHTML = this.htmlContent;

        this.contentHeight = `${ htmlElementHeight(this.$refs.contentWrapper) }px`;
        await asyncTimeout(10);
        await this.$nextTick();
        this.localCollapsed = collapsed;

        this.transitionTimeout = asyncTimeout(300);
        await this.transitionTimeout;
        this.transitionTimeout = null;
        this.contentHeight = null;
      }
    },
    mounted() {
      if (this.htmlContent)
        this.$refs.contentWrapper.innerHTML = this.htmlContent;
    }
  };
</script>
