var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"language-select"},[_c('div',{staticClass:"language-select--selected",attrs:{"tabindex":"0"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm._toggleOptions($event)},"click":_vm._toggleOptions}},[_c('div',{staticClass:"language-select--selected-value"},[_vm._v(_vm._s(_vm.selectedValue))]),_c('svg',{staticClass:"language-select--background",attrs:{"height":"85.458","width":"76.246208"}},[_c('path',{staticStyle:{"opacity":"1","fill":"none","stroke":"#ffffff","stroke-width":"3","stroke-miterlimit":"4","stroke-dasharray":"none","stroke-dashoffset":"0","stroke-opacity":"1"},attrs:{"id":"path3401","d":[
          'M 74,37.5',
          'C 74,57.65839 57.65839,74.00001 37.5,74.00001',
          'L 11.46099,84.30355 17.74412,68.19654',
          'C 7.67007,61.6996 1,50.37875 1,37.5 1,17.34162 17.34161,1 37.5,1 57.65839,1 74,17.34162 74,37.5',
          'Z'
        ].join(' '),"inkscape:connector-curvature":"0","sodipodi:nodetypes":"scccsss"}})])]),_c('div',{class:[
    'language-select--options',
    { 'language-select--options_visible': _vm.isVisible }
  ]},_vm._l((_vm.normalizedOptions),function(option){return _c('div',{key:option.value,class:[
        'language-select--option',
        { 'language-select--option_active': _vm.selectedValue === option.value }
      ],attrs:{"tabindex":"0"},on:{"click":function($event){_vm.$emit('input', option.value); _vm.$emit('change', option.value); _vm._hideOptions();},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.$emit('input', option.value); _vm.$emit('change', option.value); _vm._hideOptions();}}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }