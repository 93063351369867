<template>
  <div class="focus-frame" :style="styles">
    <div class="focus-frame--wrapper">
      <div class="focus-frame--left-border"></div>
      <div class="focus-frame--top-border"></div>
      <div class="focus-frame--right-border"></div>
      <div class="focus-frame--bottom-border"></div>
    </div>
  </div>
</template>

<style src="./focus-frame.less" lang="less"></style>

<script>
  import orientationMixin from "../mixins/orientation.js";

  const FOCUS_FRAME_OFFSET = 8;
  export default {
    name: "focus-frame",
    mixins: [orientationMixin],
    computed: {
      styles() {
        if (!this.targetBCR)
          return {};

        return {
          top: Math.round(this.targetBCR.top - FOCUS_FRAME_OFFSET) + "px",
          left: Math.round(this.targetBCR.left - FOCUS_FRAME_OFFSET) + "px",
          width: Math.round(this.targetBCR.right - this.targetBCR.left + FOCUS_FRAME_OFFSET * 2) + "px",
          height: Math.round(this.targetBCR.bottom - this.targetBCR.top + FOCUS_FRAME_OFFSET * 2) + "px"
        };
      }
    },
    data() {
      return {
        target: null,
        targetBCR: null,
        isEnterPressed: false
      };
    },
    created() {
      this.removeTarget = this.removeTarget.bind(this);
      this._keyupListener = this._keyupListener.bind(this);
      this._keydownListener = this._keydownListener.bind(this);
      this._scrollListener = this._scrollListener.bind(this);
    },
    methods: {
      setTarget(target) {
        if (!target || target.tagName === "BODY") {
          this.target = null;
          return;
        }

        this.target = target;
        this.targetBCR = target.getBoundingClientRect();
      },
      removeTarget() {
        this.target = null;
        this.targetBCR = null;
      },
      isFocusFrameActive() {
        return this.target != null;
      },
      followFocusTarget(duration) {
        clearInterval(this.followInterval);
        clearTimeout(this.stopFollowTimeout);

        this.followInterval = setInterval(() => {
          this.setTarget(this.target);
        }, 10);

        this.stopFollowTimeout = setTimeout(() => {
          clearInterval(this.followInterval);
        }, duration || 500);
      },
      _keyupListener(event) {
        if (event.defaultPrevented) {
          return;
        }

        if (event.keyCode === 9) {
          this.setTarget(document.activeElement);
        }

        if (this.isEnterPressed) {
          this.isEnterPressed = false;
          this.setTarget(document.activeElement);
        }

        if (document.activeElement) {
          const followTargetDuration = document.activeElement.getAttribute("data-focus-frame-follow");
          if (followTargetDuration != null) {
            this.followFocusTarget(parseInt(followTargetDuration));
          }
        }
      },
      _keydownListener(event) {
        if (event.keyCode === 13 && this.isFocusFrameActive()) {
          this.isEnterPressed = true;
        }
      },
      _scrollListener() {
        if (!this.isFocusFrameActive()) {
          return;
        }

        this.setTarget(document.activeElement);
      }
    },
    mounted() {
      window.addEventListener("keydown", this._keydownListener);
      window.addEventListener("keyup", this._keyupListener);
      window.addEventListener("scroll", this._scrollListener);
      window.addEventListener("click", this.removeTarget);
      window.addEventListener("wayfinder-blur", this.removeTarget);
    },
    beforeDestroy() {
      window.removeEventListener("keydown", this._keydownListener);
      window.removeEventListener("keyup", this._keyupListener);
      window.removeEventListener("scroll", this._scrollListener);
      window.removeEventListener("click", this.removeTarget);
      window.addEventListener("wayfinder-blur", this.removeTarget);

    }
  };
</script>
